//@import "fonts";
@import "reset";
@import "responsive";
@import "typography";
@import "advertisement";

:root {
  --font-manrope: Manrope, sans-serif;
  --color-gray-storm: #697488;
}

html,
body {
  width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  font-family: var(--font-manrope);
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.page {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.my-swal-hidden-outline-confirm-button {
  &:focus {
    outline: none !important;
    box-shadow: unset !important;
  }
}

$base-duration: 250ms;
$black: #000;
$spacer: 1rem;

.accordion {
  margin-bottom: 20px;

  &-item {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    overflow: hidden;
  }

  &-trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: fit-content;

    h4 {
      margin: 0;
      font-weight: 800;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0;
      color: $black;

      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }

    }
  }


  &-content {
    max-height: 0;
    overflow: hidden;
    transition: $base-duration ease;

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0;
      color: $black;
      margin: 0;
      opacity: 0;
      transform: translateY(-$spacer*2);
      transition: $base-duration*1.5 ease;
    }

    &.is-active {
      max-height: 65rem;

      p {
        opacity: 1;
        transform: translateY(0);
        transition: $base-duration*1.5 ease;
      }
    }
  }

}
