.advertisement-content-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 40px 24px 65px 24px;
  background: rgba(0, 0, 0, 0.03);

  > .advertisement-content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 100px;
    padding: 0 calc(50vw - 575px);

    @include respond-below(sm) {
      grid-template-columns: 1fr;
      grid-gap: 0;
      padding: 0;
    }

    > .advertisement-content-img {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 100% 0 0 0;
      margin: 0 0 0 0;
      box-sizing: border-box;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
      }
    }

    > .advertisement-content-text {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0 0 0 0;
      margin: 0 0 0 0;
      box-sizing: border-box;

      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0;
        color: #000000;
        margin-bottom: 15px;
      }

    }
  }

}
