$brandColor: #D2147D;
$whiteColor: #FFFFFF;
$blackColor: #000000;

h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
li {
  font-family: Manrope, sans-serif;
  position: relative;
  max-width: 100%;
  width: fit-content;

  &.text-center {
    width: 100%;
    text-align: center;
  }

  a {
    color: darken($brandColor, 10%);
    transition: color 0.3s ease-in-out;

    &:hover {
      color: $brandColor;
    }
  }

  strong {
    font-weight: 800;
  }

  i {
    font-style: italic;
  }
}

li {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: 30px;
}

ol {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding-left: 25px;
  margin-top: 30px;

  > li {
    list-style: decimal;
  }
}

@mixin fontSizeLineHeightWeight($size, $height, $weight) {
  font-size: $size;
  line-height: $height;
  font-weight: $weight;
}

h1 {
  margin-top: 17px;
  @include fontSizeLineHeightWeight(24px, 60px, 400);
  @include respond-below(sm) {
    @include fontSizeLineHeightWeight(20px, 38px, 400);
  }
}

h2 {
  margin-top: 24px;
  letter-spacing: 0;
  @include fontSizeLineHeightWeight(18px, 55px, 800);
  @include respond-below(sm) {
    @include fontSizeLineHeightWeight(18px, 30px, 800);
  }
}

h3 {
  margin-top: 32px;
  letter-spacing: 0;
  @include fontSizeLineHeightWeight(18px, 30px, 800);
  @include respond-below(sm) {
    @include fontSizeLineHeightWeight(16px, 24px, 800);
  }
}

h4 {
  margin-top: 22px;
  @include fontSizeLineHeightWeight(16px, 26px, 500);
}

h5,
h6 {
  margin-top: 22px;
  @include fontSizeLineHeightWeight(16px, 25px, 500);
}

p,
li {
  letter-spacing: 0;
  margin-top: 17px;
  margin-bottom: 17px;
  @include fontSizeLineHeightWeight(14px, 25px, 400);
  @include respond-below(sm) {
    @include fontSizeLineHeightWeight(16px, 24px, 400);
  }
}

a {
  cursor: pointer;
  color: darken($brandColor, 10%);
  transition: color 0.3s ease-in-out;

  &:hover {
    color: $brandColor;
  }
}
